import React from 'react';

const DokumentyDoPobrania = (props) => {
  return (
    <React.Fragment>
      <h3 className="page-header firstColor">Dokumenty do pobrania</h3>

      <h4 className="secondColor">Warunki ubezpieczenia</h4>

      <ul>
        {/* <li>
          <a
            target="_blank"
            href="/public-assets/tvp/2022/Ogólne_warunki_ubezpieczenia_TUW_PZUW_Opieka_Medyczna.pdf">
            OGÓLNE WARUNKI GRUPOWEGO UBEZPIECZENIA TUW PZUW OPIEKA MEDYCZNA (OM)
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/public-assets/tvp/2022/Dokument_zawierający_informacje_o_produkcie_ubezpieczeniowym_TUW_PZUW_Opieka_Medyczna.pdf">
            Dokument zawierający informacje o produkcie ubezpieczeniowym (OM)
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/public-assets/tvp/2022/Zakres_opieki_medycznej.pdf">
            Zakres opieki medycznej
          </a>
        </li> */}
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/public-assets/tvp/2022/zapisy_umowyWSP.pdf">
            Wyciąg zapisów z umowy
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/public-assets/tvp/2022/Załącznik_nr_1_Zakres_podstawowy_TVP.pdf">
            Zakres podstawowy
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/public-assets/tvp/2022/Załącznik_nr_2_Zakres_rozszerzony_TVP.pdf">
            Zakres rozszerzony
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/public-assets/tvp/2022/Załącznik_nr_3_Zakres_VIP_TVP.pdf">
            Zakres VIP
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/public-assets/tvp/2022/lista_placowek_medycznych_12.xlsx">
            Wykaz placówek medycznych
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/public-assets/tvp/2022/Załącznik_nr_5_Wykaz_minimalnej_liczby_wymagalnych_placówek_medycznych_aktualizacja_grudzień2024.pdf">
            Wykaz minimalnej liczby wymagalnych placówek medycznych (w tym
            całodobowych punktów pomocy doraźnej)
          </a>
        </li>
        {/* <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/public-assets/tvp/2022/Ogólne_warunki_ubezpieczenia_Medycyna_Pracy.pdf">
            Ogólne warunki ubezpieczenia TUW PZUW Medycyna Pracy (MP)
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/public-assets/tvp/2022/Dokument_zawierający_informacje_o_produkcie_ubezpieczeniowym_Medycyna_Pracy.pdf">
            Dokument zawierający informacje o produkcie ubezpieczeniowym (MP)
          </a>
        </li> */}
        <li>
          <a
            target="_blank"
            href="/public-assets/tvp/2022/Ogólne_warunki_ubezpieczenia_TUW_PZUW_Opieka_Medyczna.pdf">
            OGÓLNE WARUNKI GRUPOWEGO UBEZPIECZENIA TUW PZUW OPIEKA MEDYCZNA (OM)
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/public-assets/tvp/2022/Dokument_zawierający_informacje_o_produkcie_ubezpieczeniowym_TUW_PZUW_Opieka_Medyczna.pdf">
            Dokument zawierający informacje o produkcie ubezpieczeniowym (OM)
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/public-assets/tvp/2022/Zakres_Medycyny_Pracy.pdf">
            Zakres ubezpieczenia Medycyna pracy
          </a>
        </li>
      </ul>

      <h4 className="secondColor">Instrukcje</h4>

      <ul>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/public-assets/tvp/2022/Przewodnik_ubezpieczonego.pdf">
            Przewodnik ubezpieczonego
          </a>
        </li>
        {/* <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/public-assets/tvp/2022/brak.pdf">
            FAQ – najczęściej zadawane pytania i odpowiedzi
          </a>
        </li> */}
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/public-assets/tvp/2022/Zwrot_kosztów.pdf">
            Zwrot kosztów – odpowiedzi na podstawowe pytania
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/public-assets/tvp/2022/moje.pzu_jak_umówić_się_na_badania.pdf">
            mojePZU – jak umówić się na badania
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/public-assets/tvp/2022/Jak_odwołać_wizytę_lub_badanie.pdf">
            Jak odwołać wizytę
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/public-assets/tvp/2022/Dodanie_dziecka_do_profilu .pdf">
            Dodanie dziecka do profilu rodzica na mojePZU
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/public-assets/tvp/2022/E-Recepta_Kontynuacja_leczenia.pdf">
            E-Recepta - Kontynuacja leczenia
          </a>
        </li>
      </ul>

      <h4 className="secondColor">Wnioski</h4>

      <ul>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/public-assets/tvp/2022/Formularz_zgłoszeniowy_refundacja.pdf">
            Formularz zgłoszeniowy – Refundacja
          </a>
        </li>
      </ul>

      <h4 className="secondColor">Inne</h4>
      <ul>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/public-assets/tvp/2022/Informacja_administratora.pdf">
            RODO – Obowiązek Informacyjny
          </a>
        </li>
      </ul>
    </React.Fragment>
  );
};

export default DokumentyDoPobrania;
